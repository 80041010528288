import * as React from "react";
import { graphql } from "gatsby";
import styled, { keyframes } from "styled-components";
import Seo from "src/components/global/Seo.js";

import Nav from "src/components/global/Nav.js";
import Line from "src/components/global/Line.js";
import Body from "src/components/global/Typo/Body.js";
import main from "src/assets/styles/main.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import distances from "src/assets/styles/distances.js";
import typographySizes from "../assets/styles/typographySizes";
import colors from "src/assets/styles/colors.js";
import Img from "gatsby-image";

import Title from "src/components/global/Typo/BigTitle.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Footer from "src/components/global/Footer.js";
import Animate from "src/components/global/Typo/Animate.js";
import AnimateBody from "src/components/global/Typo/AnimateBody.js";
// markup

const Wrapper = styled.div`
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.pageMarginSmall}rem;
  }
`;
const show = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;
const TitleTop = styled.h2`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: var(--rest);
  width: 31.25%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 56.25%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const StyledWrapper = styled(MainWrapper)`
  padding-top: ${distances.topMargin}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${distances.topMarginMobile}rem;
  }
`;

const MainInfoRow = styled.div`
  margin-top: 50rem;
  margin-bottom: 50rem;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  hr {
    display: none;
  }

  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 20rem;
    hr {
      display: block;
    }
  }
`;
const MainInfoLeft = styled.div`
  display: flex;
  align-items: flex-end;
  width: 37.5%;
  opacity: 0;
  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin: 20rem 0;
    align-items: flex-start;
  }
`;

const AdressWrapper = styled.a`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  text-decoration: none;
  width: 50%;
  line-height: ${typographySizes.sh};
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 10rem);
    margin-right: 10rem;
  }
`;

const Telephone = styled.a`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  text-decoration: none;
  width: 50%;
  line-height: ${typographySizes.sh};
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 10rem);
    margin-right: 10rem;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    margin-top: 20rem;
    width: 100%;
  }
`;
const SocialLink = styled.a`
  font-size: ${typographySizes.s}rem;

  display: inline-block;

  text-decoration: none;
  color: var(--rest);
  margin-left: 50rem;
  :first-of-type {
    margin-left: 0;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    margin-left: 0;
    margin-right: 0rem;
    width: 50%;
    line-height: ${typographySizes.sh};
    /* margin-bottom: 10rem; */
  }
`;

const BottomRow = styled.div`
  margin-top: 50rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 100rem;
  opacity: 0;
  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    flex-wrap: wrap;
    margin-bottom: 50rem;
    margin-top: 20rem;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 56.25%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;

const ContactContainer = styled.div`
  width: 33.33%;
  align-self: flex-start;
  @media (max-width: ${mediaQuery.laptop}) {
    width: auto;
    margin-right: 0rem;
    width: 50%;
    /* margin-bottom: 20rem; */
  }
`;

const Mail = styled.a`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  text-decoration: none;
`;

const StyledImg = styled(Img)`
  width: 37.5%;
  border-radius: ${main.radius}rem;
  opacity: 0;
  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-top: 50rem;
  }
`;

const BigBottomTitle = styled(Title)`
  position: absolute;
  bottom: -2.35vw;
  left: 0;
  /* line-height: 0; */
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const Contact = ({ data, pageContext }) => {
  return (
    <Wrapper>
      <Seo
        title={data.strapiPageContact.Seo && data.strapiPageContact.Seo.Title}
        description={
          data.strapiPageContact.Seo && data.strapiPageContact.Seo.Description
        }
        image={
          data.strapiPageContact.Seo &&
          data.strapiPageContact.Seo.Img.localFile.publicURL
        }
      />
      <Nav
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
      />
      <StyledWrapper>
        <TitleTop className="intro-text">
          <AnimateBody text={data.strapiPageContact.Top_text} />
        </TitleTop>
        <MainInfoRow>
          <Line className={"animate"} />
          <MainInfoLeft>
            <AdressWrapper
              href={data.strapiContactLink.Map_link}
              target="_blank"
            >
              {data.strapiContactLink.Street} <br />{" "}
              {data.strapiContactLink.Zip_code} {data.strapiContactLink.City}{" "}
              <br />
            </AdressWrapper>
            <Telephone href={`tel:${data.strapiContactLink.Main_tel}`}>
              T: {data.strapiContactLink.Main_tel}
            </Telephone>
          </MainInfoLeft>
          <Line className={"animate"} />
          <SocialWrapper>
            {data.strapiContactLink.Social_link.map((LinkInfo) => (
              <SocialLink href={LinkInfo.Link} target="_blank">
                {LinkInfo.Name}
              </SocialLink>
            ))}
          </SocialWrapper>
        </MainInfoRow>
        <Line className={"animate"} />
        <BottomRow>
          <ContactWrapper>
            {" "}
            {data.strapiPageContact.Contact_information.map((pos) => (
              <ContactContainer>
                <Body>{pos.Text}</Body>
                <Mail href={`mailto:${pos.Mail}`} target="_blank">
                  {pos.Mail}
                </Mail>
              </ContactContainer>
            ))}
          </ContactWrapper>
          <StyledImg
            loading="eager"
            fluid={data.strapiPageContact.Image.localFile.childImageSharp.fluid}
          />
          <BigBottomTitle>
            <Animate text={data.strapiPageContact.Bottom_title} />
          </BigBottomTitle>
        </BottomRow>
      </StyledWrapper>

      <Footer
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        lang={pageContext.locale}
      />
    </Wrapper>
  );
};

export default Contact;

export const ContactQuery = graphql`
  query Contact($locale: String!) {
    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageContact(locale: { eq: $locale }) {
      Seo {
        Title
        Description
        Img {
          localFile {
            publicURL
          }
        }
      }
      Page_name
      Bottom_title
      Top_text
      Contact_information {
        Text
        Mail
      }
      Image {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
